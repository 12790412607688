import * as Sentry from '@sentry/react';
import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { SENTRY_DSN, SENTRY_ENV, SENTRY_TUNNEL } from './config-global';

const App = lazy(() => import('./app'));

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  enabled: !!SENTRY_ENV,
  tunnel: SENTRY_TUNNEL,
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/api\.geartracker\.net/],
});

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <StrictMode>
          <App />
        </StrictMode>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);
